import browser from 'browser-detect';
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { SettingsService } from './core/settings/settings.service';
import { TranslateService } from '@ngx-translate/core';
import { routeAnimations, TitleService } from './core/core.module';
import { LocalStorageService } from './core/local-storage/local-storage.service';

import { Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { AuthManagementService } from './core/auth/auth-management.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { SmartlinkManagementService } from './services/data/smartlink/smartlink-management-service.service';

@Component({
  selector: 'aula-planeta-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [routeAnimations]
})
export class AppComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public authManagementService: AuthManagementService,
    public smartlinkManagementService: SmartlinkManagementService,
    public route: ActivatedRoute,
    public router: Router,
    public localStorageService: LocalStorageService,
    public settingsService: SettingsService,
    private translateService: TranslateService,
    private titleService: TitleService
  ) {
    this.localStorageService.getItem('CURRENT_USER').pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(currentUser => {
        this.authManagementService.currentUserNext(currentUser);
        this.authManagementService.isAuthenticatedNext(true);
      });
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route),
        map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      takeUntil(this.destroy$)
      )
      .subscribe((route) => {
        const data = route.snapshot.data;
        this.titleService.setTitle(this.router.routerState.snapshot.root, this.translateService);
        
        // if (data['description']) {
        //   this.titleService.updateDescription(data['description']);
        // }
       
      });



  }

  private static isIEorEdgeOrSafari() {
    console.log('browser name:', browser().name);
    return ['ie', 'edge', 'safari'].includes(browser().name);
  }

  ngOnInit(): void {
    this.translateService.use('es');
    if (AppComponent.isIEorEdgeOrSafari()) {
      this.settingsService.changeSetting('pageAnimationsDisabled', true);
    }

    const sessionSmartlink = sessionStorage.getItem('smartlink');
    if (sessionSmartlink) {
      this.smartlinkManagementService.smartlink = JSON.parse(sessionSmartlink);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

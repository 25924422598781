/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { User } from '../../shared/models/user.model';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { Role } from '../../shared/models/role.model';
import { AuthDataService } from './auth-data.service';
import { catchError, map, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { RegistroLicencia } from '../../shared/models/registro-licencia.model';
import { VistaAlumnoService } from '../../services/vista-alumno.service';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { GtagService } from '../../services/gtag/gtag.service';
import { LtiManagementService } from '../../services/data/lti/lti-management.service';
import { RouteReuseService } from '../router-reuse/router-reuse.service';
import { ProgressSpinnerService } from '../progress-spinner/progress-spinner.service';
import { EnvService } from '../../services/env.service';
import { CookiesDataService } from '../cookies/cookies-data.service';
import { CookiesManagementService } from '../cookies/cookies-management.service';

export const AUTH_KEY = 'AUTH';
export const JWT = 'JWT';
export const CURRENT_USER = 'CURRENT_USER';
export const BACKUP_USER = 'BACKUP_USER';
export const ROLES = 'ROLES';

export interface AuthState {
  isAuthenticated: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthManagementService implements OnDestroy {

  private refreshTokenTimeout;
  redirectUrl = null;
  moodleMainUrl = null;
  userId = null;

  /**
   * Contains the isUsuarioVinculadoGoogle
   */
  private _isUsuarioVinculadoGoogle: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isUsuarioVinculadoGoogle$ = this._isUsuarioVinculadoGoogle.asObservable();

  /**
   * Contains the isUsuarioVinculadoMicrosoft
   */
  private _isUsuarioVinculadoMicrosoft: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isUsuarioVinculadoMicrosoft$ = this._isUsuarioVinculadoMicrosoft.asObservable();

  /**
   * Contains the user google
   */
  private _usuarioGoogle: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  usuarioGoogle$ = this._usuarioGoogle.asObservable();

  /**
   * Contains the microsoft user
   */
  private _usuarioMicrosoft: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  usuarioMicrosoft$ = this._usuarioMicrosoft.asObservable();

  /**
   * Contains the isAuthenticated
   */
  private _isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isAuthenticated$ = this._isAuthenticated.asObservable();

  /**
   * Contains the isAuthenticated
   */
  private _currentUser: BehaviorSubject<User> = new BehaviorSubject(null);
  public currentUser$ = this._currentUser.asObservable();

  /**
   * Contains the isAuthenticated
   */
  private _roles: BehaviorSubject<Role[]> = new BehaviorSubject(null);
  public roles$ = this._roles.asObservable();

  private _licencia: BehaviorSubject<RegistroLicencia> = new BehaviorSubject<RegistroLicencia>(null);
  public licencia$ = this._licencia.asObservable();

  private destroy$: Subject<void> = new Subject<void>();

  private _isUsuarioGoogleVinculadoById: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public _isUsuarioGoogleVinculadoById$ = this._isUsuarioGoogleVinculadoById.asObservable()


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  constructor(
    private localStorageService: LocalStorageService,
    private vistaAlumnoService: VistaAlumnoService,
    private authDataService: AuthDataService,
    private router: Router,

    private route: ActivatedRoute,
    private ltiManagementService: LtiManagementService,
    private gtagService: GtagService,
    private progressSpinnerService: ProgressSpinnerService,
    private routeReuseService: RouteReuseService,
    public envService: EnvService,
    private window: Window,
    private cookiesManagementService: CookiesManagementService
  ) {
    this.localStorageService.getItem(CURRENT_USER).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(currentUser => {
        this.currentUserNext(currentUser);
        this.isAuthenticatedNext(currentUser ? true : false);
      });

    this.localStorageService.getItem(ROLES).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(roles => {
        this.roles =
          roles && roles.length > 0
            ? roles
            : [
              {
                _id: '6038f369abb1c30015c62ece',
                desc: 'Alumno',
                name: 'Alumno'
              },
              {
                _id: '603f54851225b56b007d726b',
                desc: 'Profesor',
                name: 'Profesor'
              },
              {
                _id: '6042017e1225b56b007d726e',
                desc: 'Administrador',
                name: 'ADMIN_ROLE'
              }
            ];
      });

    this.redirectUrl = localStorage.getItem('redirectUrl');
  }

  /**
   * getter isUsuarioVinculadoGoogle
   */
  get usuarioVinculadoGoogle() {
    return this._isUsuarioVinculadoGoogle.getValue();
  }

  /**
   * getter isUsuarioVinculadoMicrosoft
   */
  get usuarioVinculadoMicrosoft() {
    return this._isUsuarioVinculadoMicrosoft.getValue();
  }

  /**
   * getter usuarioGoogle
   */
  get usuarioGoogle() {
    return this._usuarioGoogle.getValue();
  }

  /**
   * getter usuarioMicrosoft
   */
  get usuarioMicrosoft() {
    return this._usuarioMicrosoft.getValue();
  }

  /**
   * getter currentUser
   */
  get currentUser() {
    return this._currentUser.getValue();
  }

  isOnlyMaticUser() {
    return this.currentUser?.funcionalidades.length === 1 &&
      this.currentUser.funcionalidades[0].nombre === 'AccesoMatic';
  }

  /**
   * getter currentUser
   */
  set licencia(licencia: RegistroLicencia) {
    this.licenciaNext(null);
  }

  /**
   * Register
   */
  register(user: any) {
    return this.authDataService.register(user).pipe(
      map((data) => {
        this.localStorageService.setItem(JWT, data.token).pipe(
          take(1),
          takeUntil(this.destroy$)).subscribe();
        return data.user;

      }

        // ,
        // err => {
        // this.gtagService.error('sign_up', err.error.status, err.error.title);
        // }

      )
    );
  }

  /**
   * Reset password
   */
  resetPassword(user: string) {
    return this.authDataService.resetPassword(user);
  }


  /**
   * Reset password
   */
  resetPasswordForm(user: any) {
    return this.authDataService.resetPasswordForm(user);
  }


  /**
   * Login
   */
  authLogin(email: string, password: string) {
    RouteReuseService.handlers = {};
    return this.authDataService.authenticate(email, password).pipe(
      map((data) => {
        this.localStorageService.setItem(JWT, data.token).pipe(
          take(1),
          takeUntil(this.destroy$)).subscribe();
      })
    );
  }

  /**
   * Login moodle
   */
  authLoginMoodle() {
    RouteReuseService.handlers = {};
    this.localStorageService.clearDB();
    localStorage.clear();
    this.localStorageService.setItem(JWT, this.ltiManagementService.token).pipe(
      take(1),
      takeUntil(this.destroy$)).subscribe(
        () => {
          setTimeout(() => {
            this.perfil(true)
              .pipe(take(1), takeUntil(this.destroy$))
              .subscribe(() => {
                if (this.redirectUrl) {
                  this.ltiManagementService.moodle = true;
                  this.router.navigateByUrl(this.redirectUrl, { replaceUrl: true });
                  this.redirectUrl = null;
                } else {
                  this.router.navigateByUrl('/', { replaceUrl: true });
                  this.redirectUrl = null;
                }
                const info = {
                  event: 'login',
                  login: {
                    method: 'moodle'
                  }
                };
                this.gtagService.login(info);
              });
          }, 600);
        }
      );
  }

  /**
   * Login External
   */
  authLoginExternal(type, data) {
    RouteReuseService.handlers = {};
    this.localStorageService.clearDB();
    if (type === 'backoffice') {
      localStorage.clear();
    }
    // localStorage.clear();
    return this.authDataService.authenticateExternal(type, data).pipe(
      map((data) => {
        const token = type === 'Teams' ? data : data.token;

        if (!token) {

          this.router.navigate(['/login']);
          return;
        }

        this.localStorageService.setItem(JWT, token).pipe(
          take(1),
          takeUntil(this.destroy$)).subscribe();
      }),
      catchError((error) => {

        this.router.navigate(['/login']);
        return of(null);
      })
    );


  }

  /**
   * loginIp
   */
  loginIp(obj) {
    this.authDataService.loginIp().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(accessToken => {
        let bancoUri = `${this.envService.bancoUri.value}?DATA=Bearer ${accessToken.token}`;
        if (obj.utm_source) {
          bancoUri = `${bancoUri}&utm_source=${obj.utm_source}`;
        }
        if (obj.user_id) {
          bancoUri = `${bancoUri}&user_id=${obj.user_id}`;
        }
        window.open(bancoUri, '_self');
      });
  }

  /**
   * loginReferrer
   */
  loginReferrer(obj: any) {
    this.authDataService.loginReferrer(obj.referrer).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(accessToken => {
        let bancoUri = `${this.envService.bancoUri.value}?DATA=Bearer ${accessToken.token}`;
        if (obj.utm_source) {
          bancoUri = `${bancoUri}&utm_source=${obj.utm_source}`;
        }
        if (obj.user_id) {
          bancoUri = `${bancoUri}&user_id=${obj.user_id}`;
        }
        window.open(bancoUri, '_self');
      });
  }

  /**
   * loginWorldbook
   */
  loginWorldbook(obj: any) {
    return this.authDataService.loginWorldbook(obj).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(accessToken => {
        let worldbookUri = `${this.envService.worldbookUri}?DATA=Bearer ${accessToken.token}`;
        if (obj.utm_source) {
          worldbookUri = `${worldbookUri}&utm_source=${obj.utm_source}`;
        }
        if (obj.user_id) {
          worldbookUri = `${worldbookUri}&user_id=${obj.user_id}`;
        }
        window.open(worldbookUri, '_self');
      });
  }


  /**
   * Login
   */
  perfil(userMoodle?: boolean) {
    this.cookiesManagementService.getCookies();
    return this.authDataService.perfil().pipe(
      map((data) => {
        if (
          data.loginTipo && data.loginTipo === 'Find'
        ) {
          this.localStorageService.getItem('JWT')
            .pipe(takeUntil(this.destroy$))
            .subscribe(jwt => {
              const findURI = `${this.envService.rootContentFind}/login/external/backoffice?token=${jwt}`;
              this.authLogout();
              window.open(findURI, '_self');
            });

        }

        if (
          data.loginTipo && data.loginTipo === 'Rocket'
        ) {
          this.localStorageService.getItem('JWT')
            .pipe(takeUntil(this.destroy$))
            .subscribe(jwt => {
              const findURI = `${this.envService.rootContentRocket}/login/external/backoffice?token=${jwt}`;
              this.authLogout();
              window.open(findURI, '_self');

            });

        }

        if (
          data.funcionalidades
          && data.funcionalidades.length === 1 && data.funcionalidades.some(permiso => permiso.nombre === 'BancoContenidos')
        ) {
          this.localStorageService.getItem('JWT')
            .pipe(takeUntil(this.destroy$))
            .subscribe(jwt => {
              const bancoUri = `${this.envService.bancoUri.value}?DATA=Bearer ${jwt}`;
              this.authLogout();
              window.open(bancoUri, '_self');
            });

        }
        if (userMoodle) {
          data.moodle = true;
        } else {
          localStorage.removeItem('contextId');
          localStorage.removeItem('moodleTeacherId');
        }
        this.localStorageService.setItem(BACKUP_USER, data).pipe(
          take(1),
          takeUntil(this.destroy$)).subscribe();
        this.vistaAlumnoService.vistaAlumno = data.usuarioRoles.includes('Alumno');
        this.currentUserNext(data);
        this.isAuthenticatedNext(true);
        if (!(data.usuarioRoles.includes('Alumno') && this.ltiManagementService.moodle)) {
          this.loadUserGuinding();
          setTimeout(() => {
            this.userGuidingIdentify();
          }, 2000);
        }
      })
    );
  }

  /**
   * isUsuarioVinculado
   */
  isUsuarioVinculadoGoogle() {
    return this.authDataService.isUsuarioVinculadoGoogle().pipe(
      take(1),
      takeUntil(this.destroy$)
    ).subscribe(resp => {
      this._isUsuarioVinculadoGoogle.next(resp.value);
    });
  }

  /**
   * obtenerUrlLogin
   */
  obtenerUrlLoginGoogle() {
    return this.authDataService.obtenerUrlLoginGoogle();
  }

  /**
   * obtenerUrlLogin
   */
  obtenerUrlLoginMicrosoft() {
    return this.authDataService.obtenerUrlLoginMicrosoft();
  }

  /**
   * obtener Usuario Google
   */
  getGoogleUser() {
    return this.authDataService.getGoogleUser().pipe(
      take(1),
      takeUntil(this.destroy$)
    ).subscribe(resp => {
      this._usuarioGoogle.next(resp);
    });
  }

  /**
   * obtener Usuario Microsoft
   */
  getMicrosoftUser() {
    return this.authDataService.getMicrosoftUser().pipe(
      take(1),
      takeUntil(this.destroy$)
    ).subscribe(resp => {
      this._usuarioMicrosoft.next(resp);
    });
  }

  /**
   * isUsuarioVinculado
   */
  isUsuarioVinculadoMicrosoft() {
    return this.authDataService.isUsuarioVinculadoMicrosoft().pipe(
      take(1),
      takeUntil(this.destroy$)
    ).subscribe(resp => {
      this._isUsuarioVinculadoMicrosoft.next(resp.value);
    });
  }

  /**
   * Login
   */
  authLoginOffline() {
    this.localStorageService.getItem(BACKUP_USER).pipe(
      take(1),
      takeUntil(this.destroy$)).subscribe(backupUser => {
        this.currentUserNext(backupUser);
        this.isAuthenticatedNext(true);
      });
    // this.startRefreshTokenTimer();
    return of('');
  }


  vincularByUserId(userId: string): Observable<any> {
    return this.authDataService.vincularUsuarioGoogleByUserId(userId).pipe(
      take(1),
      takeUntil(this.destroy$),
      tap(resp => this._isUsuarioGoogleVinculadoById.next(resp.success))
    );
  }

  /**
   * Logout
   */
  authLogout() {
    this.router.navigateByUrl('/login/exit');
    this.progressSpinnerService.spin$.next(true);
    this.localStorageService.clearDB();
    localStorage.clear();

    this.redirectUrl = null;
    this.currentUserNext(null);
    this.isAuthenticatedNext(false);
    this.stopRefreshTokenTimer();
  }

  /**
   * Logout
   */
  authLogoutNotClean(redirectUrl) {
    this.redirectUrl = (this.redirectUrl) ? this.redirectUrl : redirectUrl;
    this.currentUserNext(null);
    this.isAuthenticatedNext(false);
    this.stopRefreshTokenTimer();
    this.router.navigate(['/login']);
    this.localStorageService.removeItem(JWT).pipe(
      take(1),
      takeUntil(this.destroy$)).subscribe();
  }



  refreshToken() {
    return this.authDataService.refreshToken().pipe(
      map((user) => {
        this.currentUserNext(user.user);
        return user;
      })
    );
  }

  changePassword(password) {
    return this.authDataService.changePassword(password);
  }

  anadirLicencia(licencia: string) {
    return this.authDataService.anadirLicencia(licencia).pipe(
      take(1),
      takeUntil(this.destroy$)
    ).subscribe(resp => {
      this.perfil().pipe(
        take(1),
        takeUntil(this.destroy$)
      ).subscribe();
    });

  }

  validaLicencia(licencia: string) {
    return this.authDataService.validaLicencia(licencia).pipe(
      take(1),
      takeUntil(this.destroy$)
    ).subscribe(
      resp => {
        this.licenciaNext(resp);
      },
      err => {
        const info = {
          event: 'error',
          error: {
            method: 'validate_license',
            errorId: err.error.status,
            errorMessage: err.error.title,
            pageId: 'register'
          }
        };

        this.gtagService.error(info);
      }
    );
  }

  validaLicenciaCaducidad(licencia: string) {
    return this.authDataService.validaLicencia(licencia).pipe(
      take(1),
      takeUntil(this.destroy$)
    );
  }

  anadirLicenciaCaducidad(payload: any) {
    return this.authDataService.setLicenciaValida(payload).pipe(
      take(1),
      takeUntil(this.destroy$)
    );
  }

  /**
   * Check if user has roles
   * @param hasRoles
   */
  userCanRoles(hasRoles: string[]): boolean {
    return hasRoles.some((role) => this.currentUser.usuarioRoles.includes(role));
  }

  /**
   * Check if user has roles
   * @param hasPermission
   */
  userCanPermission(hasPermission: string[]): boolean {
    return hasPermission.some((permission) => this.currentUser?.funcionalidades &&
      this.currentUser.funcionalidades.some((permiso) => (permiso.nombre === permission)));
  }

  /**
   * getter isAuthenticated
   */
  public get isAuthenticated() {
    return this._isAuthenticated.getValue();
  }

  /**
   * getter roles
   */
  private get roles() {
    return this._roles.getValue();
  }

  /**
   * Emit a roles
   * @param roles
   */
  private set roles(roles: Role[]) {
    this._roles.next(roles);
    this.localStorageService.setItem(ROLES, roles).pipe(
      take(1),
      takeUntil(this.destroy$)).subscribe();
  }

  /**
   * Emit a isAuthenticated
   * @param isAuthenticated
   */
  public isAuthenticatedNext(isAuthenticated: boolean) {
    this._isAuthenticated.next(isAuthenticated);
    this.localStorageService.setItem(AUTH_KEY, { isAuthenticated }).pipe(
      take(1),
      takeUntil(this.destroy$)).subscribe();
  }

  /**
   * Emit a user
   * @param user
   */
  public currentUserNext(user: User) {
    this.userId = user?.persona?.id;
    this._currentUser.next(user);
    this.localStorageService.setItem(CURRENT_USER, user).pipe(
      take(1),
      takeUntil(this.destroy$)).subscribe();
  }

  /**
 * Emit a user
 * @param password
 */
  public validatePassword(password: string) {
    return this.authDataService.validatePassword(password).pipe(
      take(1),
      takeUntil(this.destroy$)
    ).subscribe(
      resp => resp,
      err => {
        const info = {
          event: 'error',
          error: {
            method: 'validate_password',
            errorId: err.error.status,
            errorMessage: err.error.title,
            pageId: 'register'
          }
        };

        this.gtagService.error(info);
      }
    );
  }

  /**
   * Emit a user
   * @param user
   */
  public resetLicencia() {
    this._licencia.next(null);

  }

  /**
   * Emit a user
   * @param user
   */
  private licenciaNext(licencia: RegistroLicencia) {
    this._licencia.next(licencia);

  }

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    let jwtToken;
    this.localStorageService.getItem(JWT).pipe(
      takeUntil(this.destroy$)).subscribe(jwt => {
        jwtToken = JSON.parse(atob(jwt.split('.')[1]));
      });

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().pipe(
      takeUntil(this.destroy$)).subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  // Retorna si es profesor o alumno
  public getRolPrimario(): string {
    const roles = this.currentUser?.usuarioRoles;
    if (roles === undefined || roles === null) return '';
    else if (this.currentUser.usuarioRoles.includes('Profesor')) return 'Profesor';
    else if (this.currentUser.usuarioRoles.includes('Alumno')) return 'Alumno';
    return '';
  }

  public isValidRefreshToken(socialNetwork) {
    if (socialNetwork !== '') {
      return this.authDataService.isValidRefreshToken(socialNetwork).pipe(
        take(1),
        takeUntil(this.destroy$)
      );
    } else {
      return of(true);
    }
  }

  private detectDevice(): string {
    const ua = navigator.userAgent;
    if (/mobile/i.test(ua)) {
      return 'Mobile';
    } else if (/iPad|Android|Touch/i.test(ua)) {
      return 'Tablet';
    } else {
      return 'Desktop';
    }
  }

  private userGuidingIdentify() {
    const b = window as any;
    b.userGuiding?.identify(this.currentUser?.persona.id, {
      user: this.currentUser?.userName,
      email: this.currentUser?.email,
      name: `${this.currentUser?.persona.nombre} ${this.currentUser?.persona.apellidoUno} ${this.currentUser?.persona.apellidoDos}`,
      created_at: new Date().getTime(),
      usuarioRol: this.currentUser?.usuarioRol,
      moodleUserId: this.currentUser?.moodleUserId,
      isMoodle: this.currentUser?.moodleUserId ? true : false,
      idColegio: this.currentUser?.colegio?.id,
      nombreColegio: this.currentUser?.colegio?.nombre,
      idPais: this.currentUser?.colegio?.paisId,
      comunidadAutonoma: this.currentUser?.colegio?.organizacionId,
      grupo: this.currentUser.productosGrupos[0].nombre,
      device: this.detectDevice()
    });
    console.log('IDENTIFY Userguiding');
  }

  private loadUserGuinding(): void {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://static.userguiding.com/media/user-guiding-' + '516726976ID' + '-embedded.js';
    document.head.appendChild(script);

    window['userGuiding'] = window['userGuiding'] || [];
    const ug = window['userGuidingLayer'] = window['userGuidingLayer'] || { q: [] };
    ug.c = function (n) {
      return function () {
        ug.q.push([n, arguments]);
      };
    };
    const methods = ['previewGuide', 'finishPreview', 'track', 'identify', 'hideChecklist', 'launchChecklist'];
    methods.forEach(method => {
      ug[method] = ug.c(method);
    });
    console.log('LOAD Userguiding');
  }

}

<div *ngIf="!(ltiManagementService.preview$ | async) && !(temasManagementService.contextView$ | async)" 
     id="resetModulo" 
     class="position-absolute d-flex flex-column align-items-center" 
     [ngClass]="{'show': show}" 
     >
    <div>
        <button *ngIf="show" attr.aria-label="{{'dialogs.add-annotations.edit' | translate }}" 
                mat-icon-button 
                class="custom-icon default-badge" 
                (click)="openDialog($event)">
            <mat-icon class="custom-icon">history</mat-icon>
        </button>
    </div>
</div>
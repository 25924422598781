import { Component, ChangeDetectionStrategy, Inject, OnInit, OnDestroy, NgZone } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { interval, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GtagService } from '../../../../services/gtag/gtag.service';

@Component({
  selector: 'aula-planeta-adjuntar-fichero-alumno',
  templateUrl: './adjuntar-fichero-alumno.component.html',
  styleUrls: ['./adjuntar-fichero-alumno.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AdjuntarFicheroAlumnoComponent implements OnInit, OnDestroy {

  readonly maxNonVideoFileSize = 3 * 1024 * 1024;
  readonly maxVideoFileSize = 28 * 1024 * 1024;

  file: File | null = null;
  img = '';
  form: UntypedFormGroup;
  isError = false;
  isFileTooLarge = false;
  isEndUpload = false;
  progress = 0;
  private destroy$: Subject<void> = new Subject<void>();
  private uploadSubscription: Subscription | null = null;
  isDragging = false;

  docsType = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.presentation',
    'image/png',
    'image/jpeg',
    'video/mp4',
    'video/x-msvideo',
    'video/quicktime',
  ];

  private readonly uploadSpeed = 50000;

  constructor(
    public dialogRef: MatDialogRef<AdjuntarFicheroAlumnoComponent>,
    private fb: UntypedFormBuilder,
    private gtagService: GtagService,
    private zone: NgZone,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      file: [null, Validators.required]
    });

    const info = {
      event: 'popup',
      popup: {
        action: 'attach_file',
        popupId: 'dialog-adjuntar-fichero',
        popupName: 'popup_attach_file',
      },
    };
    this.gtagService.event(info);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    if (this.uploadSubscription) {
      this.uploadSubscription.unsubscribe();
    }
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    this.isDragging = false;

    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      this.handleFile(file);
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.isDragging = true;
  }

  onDragLeave(): void {
    this.isDragging = false;
  }

  handleFile(file: File): void {
    this.file = file;
    this.isError = false;
    this.isEndUpload = false;
    this.progress = 0;

    if (this.file && this.isDoc(this.file)) {
      this.startUpload(this.file);
    } else {
      this.isError = true;
      this.form.patchValue({ file: null });
      this.form.get('file')?.updateValueAndValidity();
    }
  }

  startUpload(file: File): void {
    const totalSize = file.size;
    const estimatedTime = (totalSize / this.uploadSpeed) * 1000; // Tiempo estimado en ms
    const updateInterval = 100; // Intervalos de actualización (ms)
    const steps = Math.ceil(estimatedTime / updateInterval); // Número de pasos de progreso
    const progressIncrement = 100 / steps; // Incremento del progreso en cada paso

    // Reseteamos la barra de progreso
    this.isError = false;
    this.isEndUpload = false;
    this.progress = 0;

    // Observable para simular la subida
    this.uploadSubscription = interval(updateInterval)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          // Aseguramos que Angular detecte los cambios
          this.zone.run(() => {
            this.progress += progressIncrement;

            if (this.progress >= 100) {
              this.progress = 100; // Finaliza la barra de progreso
              this.isEndUpload = true; // Marca la subida como completada

              // Finalizamos el observable
              if (this.uploadSubscription) {
                this.uploadSubscription.unsubscribe();
              }

              // Leer el archivo en formato Base64
              this.readFileAsBase64(file).then((base64: string) => {
                this.form.patchValue({
                  file: {
                    nombreFichero: file.name,
                    contenidoFichero: this.limpiarPrefijo(base64),
                  },
                });
              });
            }
          });
        },
        error: () => {
          this.zone.run(() => {
            this.isError = true;
          });
        },
      });
  }


  limpiarPrefijo(base64String) {
    // Usa replace para eliminar el prefijo de la cadena
    return base64String.replace(/^data:.*;base64,/, '');
  }

  readFileAsBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
      reader.readAsDataURL(file);
    });
  }

  deleteFile(): void {
    this.file = null;
    this.progress = 0;
    this.isEndUpload = false;

    this.form.patchValue({ file: null });
    this.form.get('file')?.updateValueAndValidity();
  }

  isDoc(file: File): boolean {
    return this.docsType.includes(file.type);
  }

  isVideo(file: File): boolean {
    const videoTypes = ['video/mp4', 'video/x-msvideo', 'video/quicktime'];
    return videoTypes.includes(file.type);
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input.files && input.files.length > 0) {
      const file = input.files[0];

      if (this.isVideo(file)) {
        if (file.size > this.maxVideoFileSize) {
          this.isFileTooLarge = true;
          this.file = null;
          this.form.patchValue({ file: null });
          this.form.get('file')?.updateValueAndValidity();
          return;
        }
      } else {
        if (file.size > this.maxNonVideoFileSize) {
          this.isFileTooLarge = true;
          this.file = null;
          this.form.patchValue({ file: null });
          this.form.get('file')?.updateValueAndValidity();
          return;
        }
      }

      this.isFileTooLarge = false;
      this.handleFile(file);
    }
  }
}
<ng-container *ngIf="(temasManagementService.recurso$ | async) as recurso">
    <ng-container *ngIf="(materiaManagementService.materia$ | async) as materia">

        <mat-toolbar *ngIf="!temasManagementService.fullscreen && !smartlinkManagementService.smartlink && showToolbar" color="primary" class="d-flex justify-content-between align-items-center">

            <div class="d-flex align-items-center">
                <ng-container>
                    <div *ngIf="!(ltiManagementService.moodle$ | async)" class="default-breadcrumb d-flex" id="breadcrumbs">
                        <div *ngFor="let breadcrumb of breadcrumbService.breadcrumbs$ | async; let first = first; let last = last" [ngClass]="breadcrumb.color">
                            <span *ngIf="!first" class="breadcrumb-separator" [ngClass]="last ? breadcrumb?.color : '' "> &gt; </span>
                            <span class="custom-text" [ngClass]="breadcrumb.link ? 'cursor-pointer fade-in' : 'fade-in' " (click)="breadcrumbService.navigateLink(breadcrumb.link); breadcrumbService.breadCrumbClickAction(breadcrumb.link)" [innerHtml]="breadcrumb.value | safe:'html'"></span>
                        </div>
                    </div>
                </ng-container>
            </div>

            <div class="d-flex pe-3">
                <div class="d-flex align-items-center position-relative">
                    <ng-template [hasRole]="['Profesor']">
                        <button id="view-menu" mat-icon-button class="custom-button position-absolute end-0 me-3" (click)="vistaAlumno.toggle(); gtagViewInfo($event)" attr.aria-label="{{'generico.vista.alumno-profesor' | translate}}" matTooltipPosition="below" [matTooltip]="'generico.vista.alumno-profesor' | translate">
                        <mat-icon class="grey-text__40">school</mat-icon>
                    </button>
                        <mat-select matTooltipPosition="below" [matTooltip]="'generico.vista.alumno-profesor' | translate" (click)="gtagViewInfo($event)" #vistaAlumno [value]="'profesor'" class="default-dropdown custom-arrow hidden-trigger-dropdown" (valueChange)="changeVistaAlumno($event); vistaAlumnoService.vistaAlumno = ($event === 'alumno') ? true : false">
                            <mat-option class="default-option" value="profesor" selected>
                                <div class="d-flex justify-content-between align-items-center">
                                    <mat-icon class="default-icon">school</mat-icon>
                                    <span>{{'generico.vista.profesor' | translate}}</span>
                                </div>
                            </mat-option>
                            <mat-option class="default-option" value="alumno">
                                <div class="d-flex justify-content-between align-items-center">
                                    <mat-icon class="default-icon">group</mat-icon>
                                    <span>{{'generico.vista.alumno' | translate}}</span>
                                </div>
                            </mat-option>
                        </mat-select>
                    </ng-template>
                </div>
                <div class="ms-3">
                    <ng-template [hasRole]="['Profesor']" [vistaAlumno]="true">
                        <button [@fade] *ngIf="!(ltiManagementService.moodle$ | async) && recurso.autor !== null" mat-icon-button class="custom-button custom-icon" (click)="editar(recurso, $event)" attr.aria-label="{{'materia.tareas.tabla.acciones.editar' | translate}}" matTooltipPosition="below"
                            [matTooltip]="'materia.tareas.tabla.acciones.editar' | translate">
                        <mat-icon>edit</mat-icon>
                    </button>
                    </ng-template>
                </div>
            </div>

        </mat-toolbar>

        <div class="d-flex flex-wrap flex-column flex-lg-row w-100" id="recurso"
          [ngClass]="{'super-fullscreen': temasManagementService.fullscreen,
                      'no-mat-toolbar': (smartlinkManagementService.smartlink || !showToolbar)} ">
            <div class="d-flex flex-wrap flex-column flex-lg-row w-100" [ngClass]="colorHelper.getColor(materia.color)">
                <div id="evaluacion" *ngIf="!temasManagementService.fullscreen || smartlinkManagementService.smartlink" class="w-50 pe-4">
                    <div class="mt-4">
                        <div class="expansible-jumbotron position-relative pb-2">
                            <div class="mb-2">
                                <div class="d-flex justify-content-between mb-5">
                                    <h1>
                                        <mat-card-title class="custom-text heading-6" rtl><span [innerHtml]="recurso.propiedades.titulo_html | safe:'html'"></span></mat-card-title>
                                    </h1>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <div class="d-flex justify-content-start align-content-center mt-2">
                                        <ng-template [hasRole]="['Profesor']" [vistaAlumno]="true">
                                            <span class="mt-1 me-2" (click)="toggleTooltip(0)">
                                                <mat-icon
                                                    *ngIf="recurso.tareasModulos?.length > 0"
                                                    class="default-icon mt-1 me-2"
                                                    attr.aria-label="{{'materia.mis-temas.tabla.acciones.asignada-tarea-moodle' | translate}}"
                                                    #tooltip="matTooltip"
                                                    [matTooltip]="tareaManagementService.getAssignmentText(recurso.id)"
                                                    [matTooltipClass]="'custom-tooltip__recurso'"
                                                    matTooltipPosition="below">
                                                    assignment
                                                </mat-icon>
                                            </span>
                                            <div [@fade] class="d-flex me-3 pt-2 align-items-center align-self-start justify-content-end menu-icon">
                                                <div *ngIf="recurso.metadatos?.nivelDificultad && recurso.metadatos?.nivelDificultad?.valor" class="bars me-2" [matTooltip]="recurso.metadatos?.nivelDificultad?.valor" matTooltipPosition="below">
                                                    <div *ngFor="let nivel of nivelesDificultad; let i = index" class="bar" [ngClass]="getNivelDificultad(i) ? 'active' : ''">
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                    <div class="d-flex align-items-center align-self-start justify-content-end">


                                        <ng-template [hasRole]="['Profesor']" [vistaAlumno]="true">
                                            <button *ngIf="!(ltiManagementService.preview$ | async)" (click)="gtagMenuInfo($event)" mat-icon-button [ngClass]="(editionModeService.enable$ | async) ? 'disable-link grey-text__lightest': 'custom-button custom-icon'" [disabled]="(editionModeService.enable$ | async)"
                                                [matMenuTriggerFor]="proyectoMenu" attr.aria-label="{{'generico.opciones' | translate}}" matTooltipPosition="below" [matTooltip]="'generico.opciones' | translate">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #proyectoMenu="matMenu" class="default-dropdown default-dropdown__module" xPosition="after" yPosition="below">
                                                <button *ngIf="!isLTI() && !materiaManagementService.materia.bloqueado" (click)="asignarTareaRapida(); gtagItem($event, 'notebook-kebabMenu')" mat-menu-item attr.aria-label="{{'materia.mis-temas.tabla.acciones.asignar-tarea' | translate}}">
                                                    <mat-icon class="custom-icon">people</mat-icon>
                                                    <span>{{'materia.mis-temas.tabla.acciones.asignar-tarea' | translate
                                                        }} </span>
                                                </button>
                                                <button *ngIf="authManagementService.currentUser.integraciones?.google && !isLTI() && !materiaManagementService.materia.bloqueado" (click)="asignarClassroom($event); gtagItem($event, 'activity-kebabMenu')" mat-menu-item attr.aria-label="{{'materia.mis-temas.tabla.acciones.asignar-tarea-classroom' | translate}}">
                                                    <mat-icon class="custom-icon">history_edu</mat-icon>
                                                    <span>{{'materia.mis-temas.tabla.acciones.asignar-tarea-classroom' |
                                                        translate }} </span>
                                                </button>
                                                <button *ngIf="authManagementService.currentUser.integraciones?.microsoft && !isLTI() && !materiaManagementService.materia.bloqueado" (click)="asignarTeams(); gtagItem($event, 'activity-kebabMenu')" mat-menu-item attr.aria-label="{{'materia.mis-temas.tabla.acciones.asignar-tarea-teams' | translate}}">
                                                    <mat-icon class="custom-icon">history_edu</mat-icon>
                                                    <span>{{'materia.mis-temas.tabla.acciones.asignar-tarea-teams' |
                                                        translate }} </span>
                                                </button>
                                                <button *ngIf="(authManagementService.currentUser.integraciones.google || authManagementService.currentUser.integraciones.microsoft) && !isMateriaOrLTI() && !materiaManagementService.materia.bloqueado" (click)="compartir($event); gtagItem($event, 'activity-kebabMenu')"
                                                    mat-menu-item attr.aria-label="{{'materia.mis-temas.tabla.acciones.compartir' | translate}}">
                                                    <mat-icon class="custom-icon">share</mat-icon>
                                                    <span>{{'materia.mis-temas.tabla.acciones.compartir' | translate }}
                                                    </span>
                                                </button>
                                                <ng-template [hasPermissions]="['Edicion']">
                                                    <button *ngIf="!(ltiManagementService.moodle$ | async)" (click)="copiar(recurso, $event)" mat-menu-item attr.aria-label="{{'materia.mis-temas.tabla.acciones.copiar' | translate}}">
                                                        <mat-icon class="custom-icon">content_copy</mat-icon>
                                                        <span>{{'materia.mis-temas.tabla.acciones.copiar' | translate }}
                                                        </span>
                                                    </button>
                                                </ng-template>
                                                <button *ngIf="!isLTI() && !materiaManagementService.materia.bloqueado" (click)="enviar($event); gtagItem($event, 'notebook-kebabMenu')" mat-menu-item attr.aria-label="{{'materia.mis-temas.tabla.acciones.enviar' | translate}}">
                                                    <mat-icon class="custom-icon">add_link</mat-icon>
                                                    <span>{{'materia.mis-temas.tabla.acciones.enviar' | translate }}
                                                    </span>
                                                </button>
                                                <button *ngIf="(ltiManagementService.moodle$ | async)" (click)="addRecursoMoodle($event)" mat-menu-item attr.aria-label="{{'materia.mis-temas.tabla.acciones.anadir-a-moodle.new' | translate}}">
                                                    <mat-icon svgIcon="anadir_a_moodle" class="custom-icon"></mat-icon>
                                                    <span>{{'materia.mis-temas.tabla.acciones.anadir-a-moodle.new' | translate }} </span>
                                                </button>
                                            </mat-menu>
                                        </ng-template>

                                    </div>
                                </div>
                            </div>

                            <div class="d-flex flex-column mb-0 w-100 filter-input">
                                <p class="d-flex mb-0 py-2 w-100 align-items-center justify-content-between">
                                    <mat-card-subtitle class="custom-text font-weight-bold mb-0 pt-0" rtl>{{'seguimiento-alumno.tarea.titulo.descripcion' | translate}}
                                    </mat-card-subtitle>
                                    <button class="more-arrow" [ngClass]="isScrolling ? 'd-none' : ''" matTooltipPosition="below" [matTooltip]="moreDataHeader ? ('generico.colapsar' | translate) : ('generico.expandir' | translate)" [attr.aria-label]="moreDataHeader ? ('generico.colapsar' | translate) : ('generico.expandir' | translate)"
                                        [attr.aria-expanded]="moreDataHeader ? 'true' : 'false'" (click)=" moreDataHeader=! moreDataHeader; gtagInfo($event, moreDataHeader)">
                                        <mat-icon class="cursor-pointer custom-text"
                                            [ngClass]="moreDataHeader ? 'expanded' : ''">expand_more</mat-icon>
                                    </button>
                                </p>

                                <div class="w-100 more-data overflow-hidden" [ngClass]="isScrolling ? 'd-none' : 'd-flex flex-wrap'" [@detailExpand]="moreDataHeader ? 'expanded' : 'collapsed'">
                                    <p class="text__small" [innerHTML]="recurso.propiedades.descripcion_html | safe:'html'"></p>
                                </div>
                            </div>

                            <ng-container *ngIf="(metadatos$ | async) as metadatos">
                                <div class="d-flex flex-column mb-0 w-100 filter-input">
                                    <p class="d-flex mb-0 py-2 w-100 align-items-center justify-content-between">
                                        <mat-card-subtitle class="custom-text font-weight-bold mb-0 pt-0" rtl>{{'seguimiento-alumno.tarea.ficha.metadatos' | translate}}
                                        </mat-card-subtitle>
                                        <button class="more-arrow" [ngClass]="isScrolling ? 'd-none' : ''" matTooltipPosition="below" [matTooltip]="moreChips ? ('generico.colapsar' | translate) : ('generico.expandir' | translate)" [attr.aria-label]="moreChips ? ('generico.colapsar' | translate) : ('generico.expandir' | translate)"
                                            [attr.aria-expanded]="moreChips ? 'true' : 'false'" (click)="moreChips=! moreChips">
                                            <mat-icon class="cursor-pointer custom-text"
                                                [ngClass]="moreChips ? 'expanded' : ''">expand_more</mat-icon>
                                        </button>
                                    </p>


                                    <div class="w-100 more-data overflow-hidden" [ngClass]="isScrolling ? 'd-none' : 'd-flex flex-wrap'" [@detailExpand]="moreChips ? 'expanded' : 'collapsed'">
                                        <!-- <p class="text__small">{{recurso.propiedades.descripcion_html}}</p> -->
                                        <mat-chip-set class="w-100 mb-3" #chipList attr.aria-label="{{'seguimiento-alumno.tarea.ficha.metadatos' | translate}}">
                                            <ng-container *ngFor="let meta of metadatos">
                                                <mat-chip *ngIf="!vistaAlumnoService.vistaAlumno || vistaAlumnoService.vistaAlumno && !meta.includes('Dificultad')" class="default-chip default-chip__raw ps-3" selectable="false" [removable]="false">
                                                    {{meta}}
                                                </mat-chip>
                                            </ng-container>
                                        </mat-chip-set>
                                    </div>
                                </div>
                            </ng-container>


                            <ng-container *ngIf="recurso.propiedades?.adjuntos?.length > 0">
                                <div class="d-flex flex-column mb-0 w-100 filter-input docs-attached">
                                    <p class="d-flex mb-0 py-2 w-100 align-items-center justify-content-between">
                                        <mat-card-subtitle class="custom-text font-weight-bold mb-0 pt-3" rtl>{{'seguimiento-alumno.tarea.titulo.documentos' | translate}}</mat-card-subtitle>
                                        <button class="more-arrow" [ngClass]="isScrolling ? 'd-none' : ''" matTooltipPosition="below" [matTooltip]="moreDocs ? ('generico.colapsar' | translate) : ('generico.expandir' | translate)" [attr.aria-label]="moreDocs ? ('generico.colapsar' | translate) : ('generico.expandir' | translate)"
                                            [attr.aria-expanded]="moreDocs ? 'true' : 'false'" (click)="moreDocs=! moreDocs">
                                        <mat-icon class="cursor-pointer custom-text"
                                            [ngClass]="moreDocs ? 'expanded' : ''">expand_more</mat-icon>
                                      </button>
                                    </p>

                                    <div class="more-data overflow-hidden docs-attached" [ngClass]="isScrolling ? 'd-none' : 'd-flex flex-wrap'" [@detailExpand]="moreDocs ? 'expanded' : 'collapsed'">
                                        <div class="w-100 docs-attached__item d-flex align-items-center justify-content-between py-2" *ngFor="let doc of adjuntos$ | async">
                                            <div class="d-flex align-items-center">
                                                <mat-icon mat-list-icon class="grey-icon">insert_drive_file</mat-icon>
                                                <span class="ps-2 grey-text text__small" [innerHTML]="doc.titulo_fichero | safe:'html'"></span>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <mat-icon class="grey-icon" [ngClass]="{'invisible': doc.visible_alumno }" matTooltipPosition="below" [matTooltip]="'dialogs.add-module-base.form.visible-profesor' | translate">group_off</mat-icon>
                                                <button (click)="link(doc.nombre_fichero, $event)" matTooltipPosition="below" [matTooltip]="'recursos.ver-documento' | translate" aria-label="'recursos.ver-documento' | translate" mat-icon-button class="ms-2 custom-button custom-icon">
                                                    <mat-icon>launch</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div class="w-50 ps-2" id="actividad">
                    <div class="scorm-container" [ngClass]="openedScorm ? 'open' : 'mt-4'">
                        <div id="ifrcontainer" class="d-flex p-0 scorm-boundary" [ngClass]="openedScorm ? 'open' : ''">

                            <!-- <div *ngIf="!startedActivity" class="position-absolute d-flex align-items-center justify-content-center w-100 h-100" style="background-color: transparent; z-index: 9000">
                    <button *ngIf="!showSpinner" (click)="realizarActividad(true, recurso)" class="custom-icon z-index-up" mat-icon-button>
                        <mat-icon class="text__xxl">play_circle_filled</mat-icon>
                    </button> -->
                            <!-- <mat-spinner *ngIf="showSpinner" class="custom-spinner" diameter="80" aria-label="Cargando"></mat-spinner> -->
                            <!-- </div> -->


                            <!-- <div *ngIf="showSpinner" class="position-absolute d-flex align-items-center justify-content-center w-100 h-100" style="background-color: transparent; z-index: 9000">
                    <mat-spinner *ngIf="showSpinner" class="custom-spinner" diameter="80" [attr.aria-label]="'generico.cargando' | translate"></mat-spinner>
                </div> -->

                            <!-- <iframe id="material" #iframe (load)="loadIframe()" [src]="urlSafe" [ngClass]="!startedActivity || showSpinner ? 'blur-active' : ''"></iframe> -->


                            <iframe #iframe
                              title="{{this.recurso.propiedades.titulo_plano}}"
                              id="material"
                              (load)="loadIframe()"
                              [src]="urlSafe"
                              allow="microphone"
                              style="background-image: url('assets/images/spinner.gif');
                                      background-position: center;
                                      background-repeat: no-repeat;
                                      background-color: #b2b2b2">
                            </iframe>
                            <button
                              (click)="openScorm($event)"
                              [ngClass]="{'youtube': isYoutube, 'fixed-button': openedScorm && !isYoutube, 'absolute-button': !openedScorm && !isYoutube}"
                              class="default-button--circle custom-bg__regular scorm-button"
                              matTooltipPosition="right"
                              [matTooltip]="openedScorm ? ('generico.reducir' | translate) : ('generico.zoom' | translate)"
                              [attr.aria-label]="openedScorm ? ('generico.zoom' | translate) : ('generico.reducir' | translate)">
                                <mat-icon class="white-text">{{ openedScorm ? 'zoom_in_map' : 'crop_free'}}</mat-icon>
                            </button>




                        </div>
                    </div>
                </div>

                <div id="fichas" class="mt-5 d-flex flex-column flex-md-row justify-content-between w-100" *ngIf="!temasManagementService.fullscreen || smartlinkManagementService.smartlink" [ngClass]="{ 'mx-4': temasManagementService.fullscreen}">
                    <ng-template [hasRole]="['Profesor']" [vistaAlumno]="true">
                        <div *ngIf="recurso.propiedades.ficha_profesor_html" [@fade] [ngClass]="recurso.propiedades.ficha_profesor_html ? 'w-100' : 'w-20'" class="me-5">
                            <div [ngClass]="recurso.propiedades.ficha_profesor_html ? 'font-weight-bold custom-text' : 'grey-text__lightest'" class="position-relative grey-border pb-3 mb-4">
                                <h2 class="title-ficha">{{'seguimiento-alumno.tarea.ficha.profesor' | translate}}</h2>
                                <button mat-icon-button class="d-none edit-button position-absolute custom-button custom-icon" matTooltipPosition="below" [matTooltip]="'generico.anadir' | translate">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </div>
                            <div class="custom-dynamic-text" [innerHTML]="this.fichaProfesor| safe:'html'"></div>
                        </div>
                    </ng-template>
                    <div *ngIf="recurso.propiedades.ficha_alumno_html" [ngClass]="recurso.propiedades.ficha_alumno_html ? 'w-100' : 'w-20'">
                        <div [ngClass]="recurso.propiedades.ficha_alumno_html ? 'font-weight-bold custom-text' : 'grey-text__lightest'" class="position-relative grey-border pb-3 mb-4">
                            <h2 class="title-ficha">{{'seguimiento-alumno.tarea.ficha.alumno' | translate}}</h2>
                            <button mat-icon-button class="d-none edit-button position-absolute custom-button custom-icon" matTooltipPosition="below" [matTooltip]="'generico.anadir' | translate">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                        <ng-template [hasRole]="['Profesor']">
                            <div class="custom-dynamic-text" [@fade] [innerHTML]="this.fichaAlumno  | safe:'html'"></div>
                        </ng-template>
                        <ng-template [hasRole]="['Alumno']">
                            <div class="custom-dynamic-text" [innerHTML]="this.fichaAlumno | safe:'html'"></div>
                        </ng-template>
                    </div>
                </div>
                <!-- <div *ngIf="(editionModeService.enable$ | async)" class="edition-backdrop" [ngClass]="(editionModeService.selected$ | async) ? 'enable' : ''"></div> -->

            </div>
        </div>
    </ng-container>
</ng-container>

import { Component, ChangeDetectionStrategy, Inject, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { interval, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { GtagService } from '../../../../services/gtag/gtag.service';

const LIMIT_UPLOAD = 101;

@Component({
  selector: 'aula-planeta-adjuntar-fichero',
  templateUrl: './adjuntar-fichero.component.html',
  styleUrls: ['./adjuntar-fichero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AdjuntarFicheroComponent implements OnInit, OnDestroy {

  file;
  img = '';
  form: UntypedFormGroup;
  isError = false;
  isEndUpload = false;

  docsType = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
  progressUpload$: Observable<any> = new Observable<any>();
  limitUpload: number = LIMIT_UPLOAD;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<AdjuntarFicheroComponent>,
    private fb: UntypedFormBuilder,
    private gtagService: GtagService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      file: [null, Validators.required],
      title: ['']
    });

    const info = {
      event: 'popup',
      popup: {
        action: 'attach_file',
        popupId: 'dialog-adjuntar-fichero',
        popupName: 'popup_attach_file'
      }
    };
    this.gtagService.event(info);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }

  onDrop(e) {
    this.file = (e as HTMLInputElement);
    this.isError = false;
    this.isEndUpload = false;

    if (this.isDoc(this.file)) {
      const url = URL.createObjectURL(this.file);
      this.form.patchValue({
        file: url
      });

      const numbers = interval(20);
      this.progressUpload$ = numbers.pipe(
        takeUntil(this.destroy$),
        take(this.limitUpload)
      );
      this.progressUpload$
        .pipe(
          takeUntil(this.destroy$))
        .subscribe(x => this.isEndUpload = (x === this.limitUpload - 1) ? true : false);
    } else {
      this.isError = true;

      this.form.patchValue({
        file: null
      });
      this.form.get('file')?.updateValueAndValidity();
    }
  }

  deleteFile() {
    this.form.patchValue({
      file: null
    });

    this.form.get('file')?.updateValueAndValidity();
  }

  isDoc(file) {
    return this.docsType.includes(file.type);
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { MainComponent } from './features/main/main.component';
import { AuthGuardService } from './core/auth/auth-guard.service';
import { Smartlink } from './services/resolvers/smartlink.service';
import { MoodleGuard } from './core/auth/moodle.guard';
import { VoidComponent } from './features/void/void.component';
import { RetroSmartlink } from './services/resolvers/retro-smartlink.service';
import { RetroSmartlinkBancoService } from './services/resolvers/retro-smartlink-banco.service';
import { AliveComponent } from './features/alive/alive.component';
import { PermalinkService } from './services/resolvers/permalink.service';
import {DescriptionResolver} from './services/resolvers/description.service';
import { RecursoViewComponent } from './features/recurso/recurso-view/recurso-view.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./features/login/login.module').then((m) => m.LoginModule),
    // data: { description: 'description.login' },
    // resolve: { description: DescriptionResolver }
  },
  {
    path: 'alive',
    component: AliveComponent
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [PermalinkService],
    children: [
      {
        path: 'mi-perfil',
        loadChildren: () => import('./features/mi-perfil/mi-perfil.module').then((m) => m.MiPerfilModule),
        canActivate: [AuthGuardService],
        canActivateChild: [MoodleGuard],
      },
      {
        path: 'mi-centro',
        loadChildren: () => import('./features/mi-centro/mi-centro.module').then((m) => m.MiCentroModule),
        canActivate: [AuthGuardService],
        canActivateChild: [MoodleGuard],
        data: { role: ['Profesor'] }
      },
      {
        path: 'textos-legales',
        loadChildren: () => import('./features/textos-legales/textos-legales.module').then((m) => m.TextosLegalesModule),
        // canActivate: [AuthGuardService],
        // canActivateChild: [MoodleGuard],
      },
      {
        path: 'inicio',
        loadChildren: () => import('./features/main/main.module').then((m) => m.MainModule),
        canActivate: [AuthGuardService],
      },
      {
        path: 'preview/materia/:id/tema/:idTema/recurso/:idRecurso',
        component: RecursoViewComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: ':page/smartlink/:id',
        component: VoidComponent,
        canActivate: [Smartlink]
      },
      {
        path: 'retrosmartlink/tema/:idTema',
        component: VoidComponent,
        canActivate: [RetroSmartlink]
      },
      {
        path: 'retrosmartlink/banco',
        component: VoidComponent,
        canActivate: [RetroSmartlinkBancoService]
      },
      {
        path: 'retrosmartlink/tema/:idTema/recurso/:idRecurso',
        component: VoidComponent,
        canActivate: [RetroSmartlink]
      },
      {
        path: 'actividad',
        loadChildren: () => import('./features/main/main.module').then((m) => m.MainModule),
        canActivate: [AuthGuardService],
        canActivateChild: [MoodleGuard]
      }
    ]
  },
  {
    path: 'teams',
    loadChildren: () => import('./features/teams/teams.module').then((m) => m.TeamsModule),
  },
  {
    path: '**',
    redirectTo: 'login'
  }
];

@NgModule({
  // useHash supports github.io demo page, remove in your app
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      scrollPositionRestoration: 'disabled',
      anchorScrolling: 'enabled',
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div id="dialog-adjuntar-fichero" class="standard">
    <h1 mat-dialog-title class="custom-text">
        {{ 'dialogs.adjuntar-fichero.titulo' | translate }}
    </h1>
    <div mat-dialog-content class="mat-dialog-content">
        <form role="form" [formGroup]="form">
            <fieldset class="w-100 d-flex flex-column">
                <!-- Zona de Arrastrar y Soltar -->
                <div class="d-flex flex-column form-group default-dropzone mb-4" (drop)="onDrop($event)"
                    (dragover)="onDragOver($event)" (dragleave)="onDragLeave()"
                    [ngClass]="{ 'dropzone-active': isDragging }">
                    <label>{{ 'dialogs.adjuntar-fichero.upload-label' | translate }}</label>
                    <div class="mb-3">
                        <!-- Mostrar Dropzone cuando no hay archivo -->
                        <div *ngIf="progress===0"
                            class="default-dropzone__container default-dropzone__container--xl d-flex align-items-center justify-content-center"
                            (click)="fileField.click()">
                            <mat-icon class="custom-icon icon">cloud_upload</mat-icon>
                        </div>

                        <!-- Mostrar Información del Archivo Subido -->
                        <div *ngIf="progress>0"
                            class="default-dropzone__container default-dropzone__container--xl d-flex align-items-center justify-content-center">
                            <div class="d-flex flex-column w-85">
                                <div class="d-flex align-items-center justify-content-between mb-4">
                                    <div class="d-flex align-items-center">
                                        <mat-icon class="custom-icon file-icon">insert_drive_file</mat-icon>
                                        <span class="grey-text ps-2">{{ file.name }}</span>
                                    </div>
                                    <span class="grey-text__light">
                                        {{ (file.size / 1024).toFixed(2) }} KB
                                    </span>
                                </div>

                                <!-- Barra de progreso -->
                                <div class="d-flex align-items-center justify-content-between">
                                    <mat-progress-bar class="default-progress-bar" mode="determinate"
                                        [value]="progress">
                                    </mat-progress-bar>

                                    <!-- Icono de subida completada -->
                                    <mat-icon class="ms-3 success-outline-icon rounded-circle"
                                        *ngIf="isEndUpload">done</mat-icon>
                                </div>
                            </div>

                        </div>
                    </div>

                    <!-- Opciones de Archivo -->
                    <div class="p-0 d-flex flex-column default-dropzone__text">
                        <p class="mb-0">
                            {{'dialogs.adjuntar-fichero.upload-info' | translate}}
                            <a [matMenuTriggerFor]="menu">
                                {{ form.get('file').value ? 'cambia' : 'añade' }}
                            </a>
                            {{'dialogs.adjuntar-fichero.upload-info2' | translate}}
                        </p>

                        <!-- Input de tipo archivo oculto -->
                        <input type="file" name="file" #fileField (change)="onFileSelected($event)" hidden />

                        <!-- Menú de opciones -->
                        <mat-menu #menu="matMenu" class="default-dropdown">
                            <button mat-menu-item (click)="fileField.click()">
                                <mat-icon class="material-icons-filled">portrait</mat-icon>
                                Seleccionar fichero
                            </button>
                            <button [disabled]="!form.get('file').value" mat-menu-item (click)="deleteFile()">
                                <mat-icon class="material-icons-filled">delete</mat-icon>
                                Borrar fichero
                            </button>
                        </mat-menu>

                        <p class="mb-0">
                            {{ 'dialogs.adjuntar-fichero.ayuda' | translate }}
                        </p>

                        <!-- Mensaje de error por archivo demasiado grande -->
                        <mat-error class="mt-0 danger-text" *ngIf="isFileTooLarge">
                            {{ 'generico.formulario.fichero-tamano-excedido' | translate }}
                        </mat-error>

                        <!-- Mensaje de error general -->
                        <mat-error class="mt-0 danger-text" *ngIf="isError && !isFileTooLarge">
                            {{ 'generico.formulario.fichero-novalido' | translate }}
                        </mat-error>
                    </div>
                </div>

                <!-- Nombre del Archivo -->
                <div class="form-group default-input mb-4">
                    <p>{{'dialogs.incidencias.formatos-soportados' | translate }}</p>
                    <ul>
                        <li>PNG, JPG, JPEG</li>
                        <li>Videos: MP4, AVI, MOV (Máximo 28MB)</li>
                        <li>PDF</li>
                        <li>Open office: ODS, ODT, ODP</li>
                        <li>Word</li>
                        <li>Excel</li>
                        <li>Power point</li>
                    </ul>
                </div>
            </fieldset>
        </form>
    </div>

    <!-- Botones de Acción -->
    <div mat-dialog-actions class="justify-content-start mt-5 mb-0">
        <button role="button" class="default-button default-button__secondary me-3" mat-dialog-close>
            {{ 'generico.cancelar' | translate }}
        </button>
        <button role="button" class="default-button default-button__primary" [mat-dialog-close]="form.value"
            type="button" [disabled]="!isEndUpload || !form.valid">
            {{ 'dialogs.adjuntar-fichero.adjuntar' | translate }}
        </button>
    </div>
</div>